.icon_black {
    color: #333 !important;
    cursor: pointer !important;
}

.icon_red {
    color: #c62828 !important;
    cursor: pointer !important;
}

.dx-row .label {
    font-size: 100%;
}

.dx-header-row > td > .dx-datagrid-text-content, .dx-header-row > td > .dx-treelist-text-content {
    white-space: normal !important;
}

.dx-dropdownlist-popup-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item-content {
    padding-left: 7px;
    padding-right: 7px;
}

.custom-item {
    position: relative;
    min-height: 30px;
}

.dx-dropdowneditor-input-wrapper .custom-item > img {
    padding-left: 8px;
}

.custom-item .item-descricao  {
    display: inline-block;
    padding-left: 50px;
    text-indent: 0;
    line-height: 30px;
    font-size: 15px;
    width: 100%;
}

.custom-item > img {
    left: 1px;
    position: absolute;
    top: 50%;
    margin-top: -15px;
}

.custom-item .dx-texteditor-buttons-container {
    display: none;
}

.current-value {
    padding: 10px 0;
}

.current-value > span {
    font-weight: bold;
}

.dx-theme-material .dx-selectbox-container .item-descricao {
    padding-left: 58px;
}

.dx-tagbox {
    min-width: 150px;
}

/* Módulo de Tarefas */
.dx_kanban {
    white-space: nowrap;
}

.list {
    border-radius: 8px;
    margin: 5px;
    width: 250px;
    background-image: linear-gradient(to top, rgba(192,192,192,0.4), rgba(192,192,192,1));
    display: inline-block;
    vertical-align: top;
    white-space: normal;
}

.titulo_lista {
    font-size: 16px;
    padding: 10px;
    margin-bottom: -10px;
    font-weight: bold;
    cursor: pointer;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.scrollable-list {
    height: 400px;
    width: 250px;
}

.sortable-cards {
    min-height: 380px
}

.card {
    position: relative;
    background-color: white;
    box-sizing: border-box;
    width: 220px;
    min-height: 60px;
    padding: 10px 20px;
    margin: 10px 10px 10px 15px;
    cursor: pointer;
}

.card-subject {
    padding-bottom: 10px;
}

.card-assignee {
    opacity: 0.6;
}

.card-priority {
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 5px;
    width: 5px;
    border-radius: 2px;
    background: #86C285;
}

.priority-1 {
    background: #ADADAD;
}

.priority-2 {
    background: #86C285;
}

.priority-3 {
    background: #EDC578;
}

.priority-4 {
    background: #EF7D59;
}

.scrollview {
    height: 600px;
}

.dx-diagram-toolbox-popup {
    z-index: 1000 !important;
}

.dx-tabs {
    max-width: 100%;
    display: inline-block !important;
}

.dx-treeview-search {
    border-radius: 24px !important;
}

.dx-treeview-buttons {
    border-radius: 24px !important;
}