/*
html, body, div, span, applet, object, iframe,
p, blockquote,
a, abbr, acronym, address, big, cite, code,
del, dfn, img, ins, kbd, q, s, samp,
strike, sub, sup, tt, var,
center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video { 
  font: inherit;
}

b, strong, h1, h2, h3, h4, h5, h6 {
  font-weight: 500 !important;
}
*/

label { 
  font-weight: inherit;
}

html, body {
  background-color: #ddd !important;
  /*font-family: 'Roboto', sans-serif !important;*/
  font-family: "Helvetica Neue","Segoe UI",helvetica,verdana,sans-serif !important;
}

.panel, .panel-heading {
  border-radius: 0 !important;
  border: 0 !important;
}
.material-theme{
  background: white !important;
  border: 0;
  box-shadow: 0px 2px 10px;
  /*padding-left: 250px !important;*/
}
.material-theme>li.active>a, .material-theme>li.active>a:hover, .material-theme>li.active>a:focus{
  background: #ddd;
  border: 0;
  border-radius: 0;
}
.material-theme>li>a{
  border: none !important;
}

.app-abas, .material-theme {
  padding-top:0 !important;
}

input[type='text'], input[type='date'], input[type='password'], textarea, button, select {
  border-radius: 0 !important;
}

.modal-content{
  border-radius: 0 !important;
}

.form-signin{
  background: #ddd;
}

[div-view]{
  /*background: #fff;*/
  padding: 5px;
}

[div-view='login/entrar']{
  background: transparent;
}

.menu_lateral_modulo {
  margin: 0;
  padding: 0;
}
#menu_lateral_modulo_lista {
  padding-top: 105px !important;
}
.container_menu_lateral {
  padding-left: 250px !important;
}
#menu_lateral {
  box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.38);
  width: 250px;
  padding: 0;
}
.icone_expande_menu {
  padding-right: 1px;
  position: absolute;
  right: 0;
}
.submenus_btn {
  display: flex;
  height: 45px;
  align-items: center;
  position: relative;
  color: #707070;
}
.submenus_btn:hover {
    background: #ddd;
}

.menu_lateral_modulo .btn_submenus {
    cursor: pointer;
    right: 0;
    position: absolute;
    align-content: center;
    top: 15px;
}

.menu_lateral_modulo .icone{
  display: flex;
  height: 100%;
  align-items: center;
  padding: 10px;
}

.submenus_item:hover{
  border-left: 5px solid #D84315;
  background: rgba(173, 173, 173, 0.08);
  color: black;
}

.submenus_item{
  margin: 0;
  padding-left: 20px;
  height: 40px;
  align-items: center;
  line-height: 25px;
}

.submenus_item .submenu_cabecalho .titulo {
  background: none;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
}

.titulo_separador{
  background: transparent !important;
}
.titulo_separador:hover{
  background: transparent !important;
}
#menu_lateral_logo {
  padding-top: 0;
    z-index: 999999;
    width: 250px !important;
    height: 100px;
}
#menu_lateral_pesquisa input {
    border: none;
    border-radius: 0;
    width: 100%;
    height: 40px;
    margin: 0;
    outline: none !important;
    top: 0;
    background-color: rgba(226, 226, 226, 0.42) !important;
}
#menu_lateral_pesquisa {
    margin: 0;
    padding: 0;
    width: 250px;
    position: absolute;
    top:65px;
}

.modal-header button.close{
  right: 0px;
  top: 2px;
  position: absolute;
  background: #F44336;
  padding: 10px;
  opacity: 1;
  width: 40px;
  height: 40px;
  color: white;
  display: flex;
  justify-content: center;
}

.modal-header{
  border-bottom: none !important;
  text-align: center;
  background: #ECEFF1;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-footer{
  padding: 5px;
  text-align: center;
  border-top: 1px solid #f3f3f3;
}

@media (min-width:1300px){.modal-xl{width:1300px;}}

/*
.dropdown-menu li{
  display: flex !important;
}
*/

button, input, textarea, select{
  outline: none !important;
}

#saved_successfully > .label{
      font-weight: normal !important;
    border-radius: 0 !important;
    padding: 20px !important;
    box-shadow: 0px 2px 6px black !important;
    display: flex !important;
}

#menu_lateral_retraido_modulo_lista{
  left: 0;
  position: absolute;
  width: 35px;
}
.breadcrumb, .breadcrumb a{
  border-radius: 0 !important;
}

.dropdown-menu{
  border-radius: 0 !important;
}

li.dropdown a{
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: left;
}
.nav-tabs>li>a{
  border-radius: 0 !important;
  margin-bottom: 0 !important;
}
.nav-tabs>li{
  margin-bottom: 0 !important;
}
p iframe{
  width: 100%;
}

@media (min-width: 768px){
  .app-abas_menu_lateral {
      padding-left: 251px !important;
  }
}

.table{
  background: #fff;
}

.ico_pesquisar{
  cursor: default;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;  
}

.dropdown-menu li a{
  width: 100%;
}

/* ---------->>> MOBILE <<<-----------*/
@media (max-width: 767px) {

  #menu_lateral_pesquisa{
    width: 290px !important;  
  }

  #menu_lateral_logo {
    width: 290px !important;
  }

  #menu_content_block{
    background: rgb(181, 181, 181);
  }
  .material-theme{
    padding-left: 0 !important;
  }
  .btns_acoes{
    display: none !important;
  }
  .btns_bottom{
    bottom: 30px;
    position: fixed;
    width: 100%;
    right: 10px;
    text-align: right;
    display: block !important;
  }

}
/* ---------->>> FIM - MOBILE <<<-----------*/

/* CLASSE PARA BLOQUEAR A SELEÇÃO DE CONTEÚDO, PARA MELHOR A EXPERIENCIA DO USUÁRIO AO NAVEGAR, COMO SE FOSSE UM APP NATIVO */
.noselect {
  cursor:default;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* IE/Edge */
  user-select: none;           /* non-prefixed version, currently
                                  not supported by any browser */
}

.logo img {
  padding-bottom: 5px;
}

.hideextra {
  white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
}

.logo {
  margin-top: 2px;
  margin-left: 8px;
  margin-right: 8px;
}

.menu_modulos ul li a {
  padding-top: 10px;
  padding-bottom: 10px;
}

#content {
    /*padding-bottom: 100px;*/
    width: 100% !important;

    -webkit-transition:  all 0.3s ease-in-out;
    -moz-transition:  all 0.3s ease-in-out;
    -ms-transition:  all 0.3s ease-in-out;
    -o-transition:  all 0.3s ease-in-out;
    transition:  all 0.3s ease-in-out;
}

.container {

}

.breadcrumb,
.breadcrumb a {
  cursor:default;
}

#loading, #saved_successfully {
  float: right;
  right: 10px;
  top: 50px;
  position: fixed;
}

.loading {

}

.img_ajax_loader {
  margin-top: 7px;
  margin-right: 4px;
  width: 24px;
  height: 24px;
}


.class-of-dropdown {
    position: relative;
    z-index: 5000;
}

.modal-body {
    overflow-y:visible;
}

.table-responsive {
	padding-bottom: 50px;
}

.div-striped>div:nth-child(even) {
    background-color:#f9f9f9;
}
.div-striped>div{
	border-bottom-width: 1px;
	border-bottom-color: #e5e5e5;
	border-bottom-style: solid;
}

.div-striped>div:last-child {
	border-bottom-style: none;
}

.metro-md {
  height: 130px;
  width: 150px;
  margin: 4px;
  padding: 0px;
  cursor: pointer;
  position: relative;
  display: inline-block;
}
@media (max-width: 530px){.btn_margins{margin-bottom:5px !important;} .btn_margin_buttom{margin-top:0px !important;}}
@media (max-width:409px){  .btn_margins{margin-bottom:5px !important;} .btn_margin_buttom{margin-top:4px !important;}}
@media (max-width: 500px) {
  .metro-md {
    width: 45% !important;
  }
  .usuario_agenda{    min-width:110px !important;  }
}

@media (max-width: 300px) {
  .metro-md {
    width: 100% !important;
  }
}

.metro-md p {
  margin-top: 13px;
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;
  position: absolute;
  font-weight: bold;
}

/* LOGIN */
.form-signin {
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}

.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 10px;
}

.form-signin .edt_usuario,
.form-signin .edt_senha {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin .edt_usuario {
  margin-bottom: -1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.form-signin .edt_senha {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* FORMULARIO */
.formulario_campo {
  padding-left: 2px;
  padding-right: 2px;
  margin: 0px;
  vertical-align: top;
}

.td_formulario_campo {
  vertical-align: top;
}

.formulario_textarea {
  resize: none;
}

.formulario_botao {
  display:table;
  margin-top:20.5px;
  min-width:150px;
}

.lbl_grupo_nome {
  margin-top: 0px !important;
  margin-bottom: 15px !important;
  border-bottom: 1px solid #ccc;
}
.form-grupos-campos {
  margin-bottom: 15px !important;
}

/* AGENDA */

.tbl_agenda {
    border-collapse:separate;
  }

  #tbl_agenda td {

  }

  .rodape {
    font-weight: bold;
  }

  .usuario_agenda {
    width: 190px;
    min-width: 190px;
  }

  .agenda_dia {
    min-width: 150px;
    max-width: 150px;
    height: 100px;
  }
  .agenda_dia:hover {
    background-color: #FFFFCC !important;

    cursor: pointer;
  }
  .agenda_evento, .projeto_atividade_evento, .oportunidade_agenda {
    margin-bottom: 2px;
    padding-left: 2px;
    padding-right: 2px;
    min-height: 40px;

  }

/* ajuste h3 */
.td-mobile h1,
.td-mobile h2,
.td-mobile h3,
.td-mobile h4,
.td-mobile h5 {
  margin-top: 10px !important;
}

/* ajuste listagem de diretorios mobile */
@media (max-width: 768px) {
  .dropdown-filtro{
    min-width:290px;
    width:290px;
    max-height:290px;
    overflow-y: scroll;

  }
  .ul_notificacoes{

  /* float: right; */max-height: 500px;position: absolute;/* float: none; */right: -150px;margin: 0;
  }
  [template-field='horas_trabalhadas']:before, [template-field='horas_previstas']:before {
    content: attr(data-title);
    text-align: center;
    font-size: 11px;
    display: block;
    margin-left: -12px;
  }
  .nav-path {
    padding-left: 15px;
  }
  input[type='number'] {
    -moz-appearance:textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
  .anexo_base_button_text {display: inline-table;top: -18px !important;left: 25px; position:relative;}
  .preview_logo {margin: 0 auto !important;display: table;}
}


.miniatura_template{

  position:relative;
  top:0;
  padding: 0px;
  margin-bottom: 5px;
  margin-top: 27px;

  transition: all .2s ease-in-out
}

.miniatura_template:hover{
  top:-4px;
  box-shadow:0 4px 3px #999;
}

.selecionar_template{

  margin-bottom: 2px;
}

.esconde_ponto {
  list-style-type: none;
}

.sem_item{

  border-style: dotted;
  border-width: 1px;
  margin-right: 20px;
  margin-left: 10px;
  height: 30px;
  border-color: #B6B6B6;
}
.wrapper {
    overflow: auto;
}
.col_fix:nth-of-type(even){
  background-color:#F5F7F9;
  z-index:3;
  position:relative;
}
.col_fix:nth-of-type(odd){
  background-color:white;
  z-index:3;
  position:relative;
}
.wrapper th{ background-color:white; position:relative;z-index:5;border-top:2px solid #C0C8CF;}/*border-top:1px solid #C0C8CF !important;*/
.menu_header{background-color:white; position:relative !important; z-index:9 !important;}
.edt_descricao{
  resize:vertical;
}
.no-padding{
  padding:0!important;
}
.margin-distance{
  margin-top: 22px;
}
.edt_observacoes{resize:vertical;}

.metro-md-preview_img {
  height: 230px;
  width: 250px;
  margin: 4px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-mobile-chamado{cursor:pointer;user-select: none; -webkit-user-select: none ; -moz-user-select: none ; -ms-user-select: none ; -o-user-select: none ;}
.menu-mobile-chamado span{font-size:15px;}
.motivo_mobile{margin-top: 1px;display: block;max-height: 50px;text-overflow: ellipsis;overflow: hidden;}
.anexo_base_button_text {display: inline-table;top: -4px;left: 25px; position:relative;}
.anexo_base_button_icon {font-size: 20px;margin-top: 8px;}
.mobile_inputfile {position: absolute;left: 0;height: 40px;opacity: 0;z-index:2;}
.mobile_file{position: absolute;height: 40px;left: 0;z-index: 2;opacity: 0;}
.label_mobilefile{height: 40px;top: 0;margin-bottom:5px;}
.anexo_base_button_text {max-width: 200px !important;text-overflow: ellipsis;overflow: hidden;}
[template-pag=apontamento_despesas-id]:before{
  content:attr(data-title);
  text-align:left;
  margin-right:5px;
}
[template-pag=apontamento_despesas-id]:after{
  content:attr(data-title-after);
  text-align:right;
  margin-left:2px;
}
[template-pag=apontamento_despesas]:before{
  content:attr(data-title);
  text-align:left;
  margin-right:5px;
}
[template-pag=apontamento_despesas]:after{
  content:attr(data-title-after);
  text-align:right;
  margin-left:0px;

}

.anexo_base_button_icon2 {
  font-size: 20px;
  margin-top: 10px;
  display: table;
  position: relative;
  float: left;
}
.anexo_base_input_text {
  position: relative;
  display: block;
  margin-top: 10px;
  max-width: 210px !important;
  text-overflow: ellipsis;
  margin-left: 8px;
  overflow-x: hidden;
  white-space: nowrap;
  float: left;
}
.capture_photo {
    font-size: 23px;
    float: left;
    margin-top: 9px;
    margin-right: 15px;
    position:relative!important;
    z-index:99999;
}
.video_responsive {
    max-width: 100% !important;
    display: block;
    max-height: 100%;
    background-cover: cover;
    background-size: cover;
    width: 100%;
}
.before-title:before{
  content:attr(data-title);
  padding:0.3em;
  padding-left:0;
}
.removeSelectMD{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
@media (max-width: 767px) {
  .removeSelect{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .float-content-filtro{
    width: 90% !important;
    left: 0 !important;
    top: 30px !important;

  }
}

@media (min-width: 768px) AND (max-width:991px) {
  .removeSelect{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .float-content-filtro{
    width: 85% !important;
    height: 400px !important;
    left: -90px !important;
    top: 30px !important;
  }
}

/*Media QUERIE MD*/
@media (max-width: 1200px){
  .conteudo_gantt{
    min-height:96.5% !important;
    height:96.5% !important;
  }
}


/**/

/*.timeline_atrasados {
  font-weight: bold;
}

.timeline_dia {
  font-weight: bold;
}*/

.gantt-atividade {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}

.gantt-baseline-cell {
  padding: 0px !important;
  margin: 0px !important;
}

.gantt-baseline {
  min-height: 10px;
  max-height: 10px;
  height: 10px;
}

.gantt-dia {
  min-width: 50px;
  text-align: center;
}

.gantt-atividade-sintetica {
  font-weight: bold;
}
.marcador{
  height: 13px;
  position: relative;
  top: 5px;
  left: 2px;
}

.marcador-border-left{
    border-radius: 0.9em 0em 0em 0.9em;
}
.marcador-border-right{
    border-radius: 0em 0.9em 0.9em 0em;
}

.marcador-border-both{
  border-radius:0.9em !important;

}

.bg-default{
  background-color:#FFF;;
}
/*Remove o contorno do botão de limpar filtro na listagem de gantt*/
.btn-limpar-filtro:focus {
    outline: 0px;
}

/*Referente ao menu novo da tela projeto_gantt - Aylon Muramatsu (2016-01-08)*/
.menu_filtro_container{
    position: fixed;
    z-index: 9999;
    width: 70px;
    bottom: 0;
    right: 0;
    height: 70px;
}
.icone_container_filtro{
    position:absolute;
    width: 60px;
    height: 60px;

    cursor:pointer;
    right: 30px;
    bottom: 50px;
    z-index: 9999;

    /*remove seleção do usuario*/
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.icone_menu_filtro {
    position:absolute;
    width: 60px;
    height: 60px;
    padding: 0 !important;
    margin: 0 !important;
    border: 2px solid #be0101;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: border 0.45s ease-in-out;
    -moz-transition: border 0.45s ease-in-out;
    -ms-transition: border 0.45s ease-in-out;
    -o-transition: border 0.45s ease-in-out;
    transition: border 0.45s ease-in-out;
    cursor:pointer;
    z-index: 9999;
    background-color: rgba(221, 53, 53, 0.36);
    /*remove seleção do usuario*/
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;

}
.icone_menu_filtro:hover, .icone_menu_filtro:active, .icone_menu_filtro:focus, .icon_menu_filtro:visited, .icon_ativo {
    border: 30px solid rgba(190, 1, 1, 0.46);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: border 0.25s ease-in-out;
    -moz-transition: border 0.25s ease-in-out;
    -ms-transition: border 0.25s ease-in-out;
    -o-transition: border 0.25s ease-in-out;
    transition: border 0.25s ease-in-out;
    padding:0 !important;
    margin:0 !important;

}

.icon_ativo:hover{
      border: 2px solid #be0101 !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
    -webkit-transition: border 0.25s ease-in-out;
    -moz-transition: border 0.25s ease-in-out;
    -ms-transition: border 0.25s ease-in-out;
    -o-transition: border 0.25s ease-in-out;
    transition: border 0.25s ease-in-out;
    padding:0 !important;
    margin:0 !important;
}
.float-button-filtro {
    z-index: 99999;
    position: absolute;
    width: 70px;
    height: 70px;
    bottom: 0;
    right: 0;
}
.icon_filter{
  position: absolute !important;
  top: 20px  ;
  left: 21px  ;
  height: 20px !important;
  width: 20px !important;
  font-size: 21px;
  color: #393939;
  z-index: 9998;

}
/*.icon_filter_active{
  top: -9px  ;
  left: -10px  ;
}*/
.effect-screen{
    display:none;
    position: fixed;
    top: 0;
    width: 100%;
    height: 2000px;
    background-color: rgba(57, 57, 57, 0.69);
    background-image: url('../assets/img/favicon_next.png');
    background-repeat: no-repeat;
    background-size: 34px;
    background-position: 7px 4px;
    opacity: 0;
    left: 0px;
    z-index: 9998;
    --webkit-transition: opacity 0.25s ease-in;
       -moz-transition: opacity 0.25s ease-in;
        -ms-transition: opacity 0.25s ease-in;
         -o-transition: opacity 0.25s ease-in;
            transition: opacity 0.25s ease-in;
}
.float-content-filtro{
    display:none;
    opacity:0;
    width: 40%;
    height: 400px;
    left: 0;
    top: 100px;
    right: 0;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    z-index: 9999;
    /*animação suave!*/
    --webkit-transition: opacity 0.25s ease-in;
       -moz-transition: opacity 0.25s ease-in;
        -ms-transition: opacity 0.25s ease-in;
         -o-transition: opacity 0.25s ease-in;
            transition: opacity 0.25s ease-in;
    /*animação suave*/
    /*remove seleção do usuario*/
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.content_ativado{
  opacity : 1;
  --webkit-transition: opacity 0.25s ease-in;
  -moz-transition: opacity 0.25s ease-in;
  -ms-transition: opacity 0.25s ease-in;
  -o-transition: opacity 0.25s ease-in;
  transition: opacity 0.25s ease-in;
}

/*Fix error DropDown - select-multi-column*/
.dropdown-backdrop{
  display:none !important;
}
.popover-exclusivo > .popover{
    max-width: inherit !important;
}

.span_trash{
    overflow: hidden;
    display: block;
    width: 0px;
    transition: width 0.5s;
    -webkit-transiction: width 0.5s;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
}

.btn_hover_trash:hover > .span_trash{
    width: 120px;
}

.filtro_personalizado, .label_configurar {
    width: 0;
    float: left;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 3px;
    transition: width 0.5s;
    overflow-x: hidden;
}


.ui-datepicker-month,.ui-datepicker-year{
  color:#393939;
}
.separator-buttons{
  margin-top:1px;
}

.cursor_social{
  position: absolute;
  left: 0px;
  border-right: 2px solid black;
  box-sizing:border-box;
}
.cursor_social_resposta{
  position: absolute;
  left: 0px;
  border-right: 2px solid black;
  box-sizing:border-box;
}
.cursor_social_resposta{
  position: absolute;
  left: 0px;
  border-right: 2px solid black;
  box-sizing:border-box;
}
#input_div{
  padding:0;
  overflow: hidden;

}
#input_div_resposta{
  padding:0;
  overflow: hidden;

}
.edt_enviar_comentario > .content{
    display: block;
    min-width: 100%;
    outline: none;
    min-height: 35px;
    padding-right:10px;
}
.edt_enviar_comentario_resposta > .content_resposta{
    display: block;
    min-width: 100%;
    outline: none;
    min-height: 35px;
    padding-right:10px;
}
.cursor_orderby {
    cursor: pointer;
}

.orderby-selected {
    font-weight: bold;
    --webkit-transform: scale(1.1);
       -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
         -o-transform: scale(1.1);
            transform: scale(1.1);
	-webkit-transition: transform .25s linear;
	-o-transition: transform .25s linear;
	transition: transform .25s linear;
}

.template-viewDrag {
  border: 1px solid #E2E2E2;
  width: 200px;
  max-width: 180px;
  min-width: 160px ;
  min-height: 120px !important;
  position: relative;
  padding: 10px;
  padding-bottom:5px;
  border-radius: 0.4em;
  margin: 5px;
  box-shadow: 0px 0px 2px 0px #9e9e9e;
  z-index:  9999999;
}
.template-viewDrag .titulo_principal {
  font-size: 0.8em;
  border-bottom: 2px solid #e2e2e2;
  margin-bottom: 6px;
  display:block;
  text-overflow:ellipsis;
  white-space:nowrap;
  width:calc(165px - 10px);
  overflow-x:hidden;
}
.template-viewDrag .container_filhos {
  max-height: 140px;
 /*overflow: hidden;*/
  min-height: 50px;
  display:table;
}
.template-viewDrag .filho_item {
  font-size: 0.7em;
  padding-left: 11px;
  padding-top:5px ;
  padding-bottom:5px;
  max-height:20px;
  min-height:18px;
  width:calc(155px - 10px);
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  display:block;
  border-bottom:2px dotted #e2e2e2;
}
.template-viewDrag .limiter{
  margin-top:10px;
  margin-bottom:0px;
}
/*HIGHLIGHT*/
.template-viewDragHighlight {
  border: 2px dotted #E2E2E2;
  width: 200px;
  max-width: 180px;
  min-width: 160px ;
  min-height: 60px !important;
  position: relative;
  padding: 10px;

  border-radius: 0.4em;
  margin: 5px;
  display:flex;
  display:-webkit-flex;
  align-items:center;
  justify-content:center;


}
.template-viewDragHighlight .container_filhos {

  min-width:100%;
  min-height:100%;
  height:100%;
  width:100%;
  color:#c2c2c2;
  font-weight:700;
  font-size:1.2em;
  text-align:center;
}

@media (min-width:992px) AND (max-width:1700px) {
  .filtro_personalizado{
    width:120px;
  }
  .label_configurar{
    width:145px !important;
  }
  .separator-buttons{
      margin-top: 5px !important;
  }
  .excessao-length{
	  /**Medida equivalente a 70 caracteres.**/
	  width:70ch;
  }
}

.not_spin::-webkit-inner-spin-button, .not_spin::-moz-inner-spin-button {
  display:none;
}
.orderbypointer,.filterpointer{
	cursor:pointer;
}

.conteudo_gantt_group{

  display:flex;
  width:100%;
  height:100%;
  min-height: 58.5em;
  clear:both;
  box-sizing:content-box;
  flex-direction:row;
  padding-bottom: 0px;
  position: absolute;
  top: 44px;
}

.conteudo_gantt{

  display:block;
  min-height:100%;
  height:100%;
  position:relative;
  top:-1px;
  width:100%;
  min-width:100%;

}

.bp_div_drop{
   position:absolute;
   background-color:white;
   border-bottom: 1px solid #e2e2e2;
   border-left:1px solid #e2e2e2;
   border-right:1px solid #e2e2e2;
   border-radius:0em 0em 0.32em 0.32em;
   box-sizing:border-box;
   -webkit-box-sizing:border-box;
   -moz-box-sizing:border-box;
   padding:0px 0px 0px 0px;
   min-width:280px;
   display:none;
   z-index:4;
   max-height:400px;
   overflow-y:scroll;
   
}
.bp_div_drop ul{
  margin-top:0px;
  padding-top:0px;
}
.bp_div_drop[data-visible="false"]{
  display:none;
}
.bp_div_drop[data-visible="true"]{
  display:block !important;
}
.bp_div_drop li{
  padding:5px 15px;
  display:block;
  position:relative;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden;
}
.bp_div_drop li:hover, .bp_div_drop li.active{
  background-color:#e2e2e2;
  margin-left:0px;
  box-sizing:border-box;
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  padding:5px 15px;
  cursor:pointer;
}
.bp_div_drop ul {
  margin-left:0px;
  padding:0px
}

.label {
  font-weight: normal !important;
}

/**Componente de Input File Next*/
.InputFileNext{
  width: 100%;
  display: block;
  padding-bottom: 10px;
  min-height: 30px;
}
.InputFileNext > .anexo_base_button{
  display: block;
  width: 100%;
}
.InputFileNext > .anexo_base_button .anexo_base_input_text{
  margin-top: 2px;
}
.InputFileNext > .anexo_base_button .anexo_base_button_icon2{
  margin-top: 0px;
}


.nav-wizard > li {
  padding: 2px;
  float: left;
}
.nav-wizard > li > a {
  position: relative;
  background-color: #eeeeee;
}
.nav-wizard > li > a .badge {
  margin-left: 3px;
  color: #eeeeee;
  background-color: #428bca;
}
.nav-wizard > li:not(:first-child) > a {
  padding-left: 34px;
}
.nav-wizard > li:not(:first-child) > a:before {
  width: 0px;
  height: 0px;
  border-top: 20px inset transparent;
  border-bottom: 20px inset transparent;
  border-left: 20px solid #ffffff;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
}
.nav-wizard > li:not(:last-child) > a {
  margin-right: 6px;
}
.nav-wizard > li:not(:last-child) > a:after {
  width: 0px;
  height: 0px;
  border-top: 20px inset transparent;
  border-bottom: 20px inset transparent;
  border-left: 20px solid #eeeeee;
  position: absolute;
  content: "";
  top: 0;
  right: -20px;
  z-index: 2;
}
.nav-wizard > li:first-child > a {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.nav-wizard > li:last-child > a {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.nav-wizard > li.done:hover > a,
.nav-wizard > li:hover > a {
  background-color: #d5d5d5;
}
.nav-wizard > li.done:hover > a:before,
.nav-wizard > li:hover > a:before {
  border-right-color: #d5d5d5;
}
.nav-wizard > li.done:hover > a:after,
.nav-wizard > li:hover > a:after {
  border-left-color: #d5d5d5;
}
.nav-wizard > li.done > a {
  background-color: #e2e2e2;
}
.nav-wizard > li.done > a:before {
  border-right-color: #e2e2e2;
}
.nav-wizard > li.done > a:after {
  border-left-color: #e2e2e2;
}
.nav-wizard > li.active > a,
.nav-wizard > li.active > a:hover,
.nav-wizard > li.active > a:focus {
  color: #ffffff;
  background-color: #428bca;
}
.nav-wizard > li.active > a:after {
  border-left-color: #428bca;
}
.nav-wizard > li.active > a .badge {
  color: #428bca;
  background-color: #ffffff;
}
.nav-wizard > li.disabled > a {
  color: #777777;
}
.nav-wizard > li.disabled > a:hover,
.nav-wizard > li.disabled > a:focus {
  color: #777777;
  text-decoration: none;
  background-color: #eeeeee;
  cursor: default;
}
.nav-wizard > li.disabled > a:before {
  border-right-color: #eeeeee;
}
.nav-wizard > li.disabled > a:after {
  border-left-color: #eeeeee;
}
.nav-wizard.nav-justified > li {
  float: none;
}
.nav-wizard.nav-justified > li > a {
  padding: 10px 15px;
}
@media (max-width: 768px) {
  .nav-wizard.nav-justified > li > a {
    border-radius: 4px;
    margin-right: 0;
  }
  .nav-wizard.nav-justified > li > a:before,
  .nav-wizard.nav-justified > li > a:after {
    border: none !important;
  }
}
/* Estilo das caixas de Atuação Efetiva e Competencias Essenciais */
.box-header .id{
    padding-left: 5px !important;
}
.box-info{
    padding: 6px;
    background: #455A64;
    color: #fff;
}
.box{
    min-height: 220px;
/*    box-shadow: 0 0px 0px rgba(0,0,0,0.16), 0 0px 2px 0px rgba(0,0,0,0.23);*/
    margin-bottom: 15px;
    padding: 0 !important;
    background: #fff;
}
.box:hover{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    transition: 0.4s all;
}
.box-body a {
    margin: 0;
    padding: 4px;
}        
.box-header{
  font-size: 15px;
  position: relative;
  background: #455A64;
  height: 50px;
  display: flex;
  justify-content: center;
  color: #fff;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.07);
}
.box-header span{
    position: relative;
    right: 0;
}
.box-header .titulo{
  position: relative;
  display: flex;
  align-items: right;
  font-size: 25px;
  font-weight: 500;
  color: #fff;
  margin: 0;
}
.box-header .id{
  position: absolute;
  margin: 0 !important;
  top: 30px;
}        
.actions{
  position: absolute;
  right: 15px;
  z-index: 10;
  height: 50px;
  display: flex;
  align-items: center;  
}
.actions span{
    padding: 8px;
}

button.truncate{
  overflow: hidden;
  text-overflow: ellipsis;  
  white-space: nowrap;
}


.box-body [template-field="descricao"]{
    display: flex;
    justify-content: center;
    min-height: 40px;
    align-items: center;
  }
[data-editor],
[data-editor]:focus{
  outline: none;
}
[data-editor][contenteditable="true"]{
  -webkit-animation-name: blink-cursor; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: .8s; /* Safari 4.0 - 8.0 */
  -webkit-animation-delay: .5s;
  animation-name: blink-cursor;
  animation-duration: .8s;
  animation-delay: .5s;
  animation-iteration-count: infinite;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

}


/* Safari 4.0 - 8.0 */
@-webkit-keyframes blink-cursor {
    from {
      -webkit-border-bottom: 2px solid #323232;
      -moz-border-bottom: 2px solid #323232;
      -ms-border-bottom: 2px solid #323232;
      -o-border-bottom: 2px solid #323232;
      border-bottom: 2px solid #323232;
    }
    to {
      -webkit-border-bottom: 2px solid transparent;
      -moz-border-bottom: 2px solid transparent;
      -ms-border-bottom: 2px solid transparent;
      -o-border-bottom: 2px solid transparent;
      border-bottom: 2px solid transparent;
    }
}

/* Standard syntax */
@keyframes blink-cursor {
    from {
      -webkit-border-bottom: 2px solid #323232;
      -moz-border-bottom: 2px solid #323232;
      -ms-border-bottom: 2px solid #323232;
      -o-border-bottom: 2px solid #323232;
      border-bottom: 2px solid #323232;
    }
    to {
      -webkit-border-bottom: 2px solid transparent;
      -moz-border-bottom: 2px solid transparent;
      -ms-border-bottom: 2px solid transparent;
      -o-border-bottom: 2px solid transparent;
      border-bottom: 2px solid transparent;
    }

}

.cbo_mask{
    height: 30px;
    background-color: rgba(199, 193, 193, 0.5);
    border-radius: 0.15em;
    -webkit-animation-name: blink-mask; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 3.5s; /* Safari 4.0 - 8.0 */
    /*-webkit-animation-delay: .9s;*/
    animation-name: blink-mask;
    animation-duration: 3.5s;
    /*animation-delay: .9s;*/
    animation-iteration-count: infinite;
}

.cbo_mask:before{
    content:'';
}

@keyframes blink-mask {
    0% {
      background-color: rgba(199, 193, 193, 0.5);
    
    }
    50% {
      background-color:rgba(206, 199, 199, 0.2);      
    }

    100%{
      background-color: rgba(199, 193, 193, 0.5);
    }
}
@-webkit-keyframes blink-mask {
    0% {
      background-color: rgba(199, 193, 193, 0.5);
    
    }
    50% {
      background-color:rgba(206, 199, 199, 0.2);      
    }

    100%{
      background-color: rgba(199, 193, 193, 0.5);
    }
}

.tarefas-row[data-realizado="true"]{
    font-style: italic;
    font-weight: bold;
    text-decoration: line-through;
    color: #c2c2c2;
}



section.tarefas-row-helper.ui-sortable-helper {
    width: 100%;
    display: flex;
    background-color: #FFF;
    height: 24px !important;
    padding: 4px 5px 3px;
    flex: 1;
    border-bottom: 1px solid #c2c2c2;
    border-top: 1px solid #c2c2c2;
    border-right:1px solid #c2c2c2;
}

.flex-input {background: white;height: 35px;width: 48%;/* border: aliceblue; *//* display: -webkit-box; *//* display: flex; */border: 1px solid #c2c2c2;/* flex-wrap: nowrap; *//* flex-direction: column; *//* margin: 0 11px; *//* flex: 2; */}

.not_number_input::-webkit-inner-spin-button, 
.not_number_input::-webkit-outer-spin-button{
    display:none;
}

.flex-group {
    min-height: 35px;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    margin: 0px 5px;
    max-width: 335px;
}

.input-group-flex {
    display: flex;
    display: -webkit-box;
    justify-content: space-between;
}

.flex-group-item {
    width: 45%;
    margin: 0 5px;
}


.freeze {
  position:relative;
  top:0px;
  z-index:3;
  background-color: #ddd;
  margin-top: -13px;
  padding-top: 10px;
}
.table-header-freezer thead th{
  position:relative;
  background:white;
  z-index:1;
}
.table-header-freezer thead tr {
    background-color: white;
}

.table-header-freezer-grade {
  table-layout: fixed;
  border-collapse: collapse;
  position:relative;
}

.table-header-freezer-grade thead{
  position:relative;
  width:100%;
}

.table-header-freezer-grade thead tr {
  position:relative;
  top:0px;
  background-color:white;
  /* width:100%; */
  min-width:100%;
  display:block;
  z-index:2;
  width: 100%;
}

.table-header-freezer-grade tbody {
  position:relative;
  width:100%;
}

.table-header-freezer-grade thead th,
.table-header-freezer-grade tbody td{
  display:inline-block;
  position:relative;
}


.dropdown-filtro{
  min-width:290px;
  overflow-y:auto;
  left:-160px;
  overflow-x:hidden;
}

.dropdown-filtro li a div{
  display:-webkit-flex;
  display:flex;
  max-width:335px;
  width:100%;
  justify-content: center;
  margin:0px;
  padding:0px;
}
.dropdown-filtro li .dropup{
  width:40%;
}

.lista_oportunidade_item {
  padding: 5px;
}

.estilo-filtro{
  background: #f9f9f9;
  margin-bottom: 10px;
  box-shadow: 1px 1px 7px rgba(0,0,0,0.16), 0 0px 2px 0px rgba(0,0,0,0.23);
}

.hiddenRow{
  padding: 0px !important;
}
.td-style{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0;
  position: relative;
  right: 0;
  width: 40px;
  height: 40px;
}
.remove-bottom-table{
  margin-bottom: 0px !important;
}
.list_login_usuarios{
  box-shadow: 2px 2px 2px #888888;
  border-radius: 3px;
}

.list_login_usuarios_outro{
  box-shadow: 2px 2px 2px #888888;
  border-radius: 3px;
}

.list_login_usuario{
  font-weight: 300 !important;
  /*border: 1px solid #888888;*/
  border-radius: 3px;
  box-shadow: 2px 2px 2px #888888
}

.form_signin_user{
  background: white;
  box-shadow: 2px 2px 2px #888888;
  border-radius: 3px;
}

.lbl_login_nome{
  font-weight: 300 !important;
  /*font-family: 'Raleway',sans-serif; */
  font-size: 18px; 
}

.BotaoResponsivo{
  margin: 2px;
}

/* Botão Responsivo */
@media (max-width: 768px){
  .BotaoResponsivo {
    width: 100%;
  }
  .btn_novo_documento span.label_btn,
  .btn_exportar_doc span.label_btn{
    display: none;
  }
}
/*.toggle_atuacao:hover{
  background-color: #ccc !important;
  cursor: pointer;
}*/
.toggle_indicador:hover{
  background-color: #f3f3f3 !important;
}

.actions-metas{
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  align-content: center;
  bottom: 0;
  height: 100%;
}
.tab_acoes thead tr:hover,
.toggle_habilidade:hover,
.toggle_atuacao:hover,
.toggle_competencias:hover,
.toggle_indicador:hover,
.tab_acoes_competencias thead tr:hover {
  background: #e4e4e4 !important;
  cursor: pointer;
}

.graph .labels.x-labels {
  text-anchor: middle;
}
.hiddenRow{
  padding: 0px !important;
}
.td-style{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0;
  position: relative;
  right: 0;
  width: 40px;
  height: 40px;
}
.remove-bottom-table{
  margin-bottom: 0px !important;
}

.graph .labels.y-labels {
  text-anchor: end;
}

.graph {
  height: 500px;
  width: 520px;
}

.graph .grid {
  stroke: #ccc;
  stroke-dasharray: 0;
  stroke-width: 1;
}

.labels {
  font-size: 13px;
}

.label-title {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  fill: black;
}

.data {
  fill: red;
  stroke-width: 1;
}

.loader{
    margin: 0 0 2em;
    width: 40%;
    text-align: center;
    padding: 1em;
    margin: 0 auto 1em;
    display: inline-block;
    vertical-align: top;
}

    .box-loader{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        display: flex;
        align-items: center;
        background: rgba(255, 255, 255, 0.88);
        margin: 0px 15px 15px 15px; 
      }
    .tab_acoes thead tr:hover,
    .toggle_habilidade:hover,
    .toggle_atuacao:hover,
    .toggle_competencias:hover,
    .toggle_indicador:hover,
    .tab_acoes_competencias thead tr:hover {
      background: #e4e4e4 !important;
      cursor: pointer;
    }

  td.botoes_acoes a {
    display: inline-flex;
    height: 20px;
    align-items: center;
  }

  /*estilo da arvore de pastas da listagem de documentos*/
  #arvore ul li a {
    white-space: nowrap;
    white-space: -moz-nowrap;
    white-space: -nowrap;
    white-space: -o-nowrap;
    word-wrap: break-word;
    height: auto;
    /*font-size: 12px;*/
  }
/* Loader no topo da tela */
.loader_bp {
	position: absolute;
	top: 0; left: 0;
	width: 100%;
	height: 7px;
  overflow: hidden;
  z-index: 1039;
}
.loader_bp .bar {
	position: absolute;
	width: 100%;
	height: 100%;
} .loader_bp .bar {
	z-index: 1;
} .loader_bp .bar {
  animation: bar 2s infinite linear;
}

@keyframes bar {
	0% {transform: translateX(-100%);}
  100% {transform: translateX(100%);} 
}

/* fix para contextmenu do jsTree aparecer no modal do bootstrap  */
.vakata-context {
   z-index: 1100 !important;
}

/* Aumento do icone do jsTree */
a .jstree-icon
{
    font-size: 18px;
}

.hpe_layout.table tbody td{
  padding: 0 !important;
  border: 1px solid #cecece 
}

.hpe_layout.table td input, .hpe_layout.table td select{
  height: 40px !important;
  border-radius: 0 !important;
  border: none;
}

.menu-vert{
  color: #fff;
}

.titulo_passo{
  background: #e0e0e0;
    padding: 10px;
    display: flex;
    margin-bottom: 0;
}

.sub_titulo_passo{
  background: #cccccc2e;
  margin: 0;
  display: inline-flex;
  width: 100%;
  color: #828282;
}
 .num_linha{
  position: absolute;
  font-size: 10px;
  padding: 3px;
  color: #b5b5b5;
}

.sub_titulo_passo span{
  display: flex;
  padding: 4px;
  align-items: center;
}


[template-field="efetividade"],
[template-field="desejado"],
[template-field="select_curva"],
.media_efetividade,
.media_desejado,
.media_qe{
  text-align: center;
}
.cor_aprovado{
  color: green !important;
}
.cor_reprovado{
  color: red !important;
}
.cor_neutro{
  color: blue !important;
}
.cor_aguardando{
  color: #9e9e9e !important;
}
.borda_aprovado{
  box-shadow: 5px 0px 0px 0px #5cb85c inset;
}
.borda_reprovado{
  box-shadow: 5px 0px 0px 0px #d9534f inset;
}
.borda_neutro{
  box-shadow: 5px 0px 0px 0px #cecece inset;
}

/*Estilos tela de metas*/
 .box-info{
    height: 60px;
    width: 100%;
  }
  .box-title{
    border: 1px solid black;
    height: 100%;
    width: 250px;
  }
  .mais{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .box-acoes{
    display: flex;
    justify-content: space-evenly;
  }
  .box-acoes button{
    margin: 5px;
    padding: 5px;
  }
  .info-acao{
    padding: 10px;
  }

  .area_click_user{
    cursor: pointer;
  }

  .area_click_user:hover{
    background: #cecece;
    transition: 0.4s all;
  }

  .titulo_meta{
    background: #161431;
    padding: 10px;
    color: #ffffff;
    display: flex;
    margin-bottom: 0;
    justify-content: center;
  }

.nome_usuario{
  background: #909090;
  color: white;
  margin: 0;
  padding: 5px;
}

.borda_pendente{
  box-shadow: 5px 0px 0px 0px #5bc0de inset;
}

.icon-on-top{
  position: relative;
  top: 7px;
  left: -7px;
  color: #000;
  /* text-shadow: -1px 2px #f5f5f5, 1px 1px #f5f5f5, 1px -2px #f5f5f5, -1px -1px #f5f5f5; */
  font-size: 12px!important;
}


.bootstrap-select.btn-group .dropdown-menu{
  min-width: 100% !important;
}

.nav>li>a:hover, .nav>li>a:focus{
  transition: .5s all;
}

/* cockpit */
.div_cockpit {
  background: inherit;
}
.div_cockpit_body {
  margin-left: 0px;
  margin-right: -4px;
  margin-bottom: 10px;
}
/* cockpit favoritos*/
.cardCockpit .col-xs-6, .cardCockpit.col-md-6, .cardCockpit .row {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.cardCockpit.col-md-6 .row {
  background: white;
  margin-right: 4px !important;
  min-height: 28px;
}
.cardCockpit h3 {
  margin-top: 0px; margin-bottom: 0px;
  font-size: 18px;
  padding-left: 0px;
  padding-right: 0px;
}
.cardCockpit .col-xs-6 span,
.cardCockpit .col-xs-12 span {
  font-size: 12px !important;
}

.cardCockpit .submenu {
  margin-right: 5px;
}

.ui-resizable-helper { 
  border: 1px dotted gray; 
}
.span_status{
  position: absolute;
  display: flex;
  font-size: 10px;
}
.clockpicker-popover{
  z-index: 9999;
}
.dx-master-detail-cell {
  overflow-x: auto !important;
}
.bg-danger {
  background-color: #f2dede !important;
}
.bg-warning {
  background-color: #fcf8e3 !important;
}
.bg-success {
  background-color: #dff0d8 !important;
}
.bg-info {
  background-color: #d9edf7 !important;
}
.info p, .info .row{
  margin: 0;
  background: #e2f2ff;
  padding: 12px;
}
.info h4{
  background: #d6d6d617;
  padding: 10px;
  margin: 0;
}
.inicial-maior{
  font-size: 24px;
  color: red;
}
.nav-bp > li{
  padding: 2px;
  float: left;
  border: 1px dashed #cac9c9;
  margin: 2px;
}
.nav-bp > li > a{
  font-size: 15px;
  background-color: whitesmoke;
  margin:0;
  padding-left: 5px;
  padding-right: 5px;
}
.nav-bp > li > a:hover{
  background-color: #888888d4;
  color: white;
}
.nav-bp > li.active > a,
.nav-bp > li.active > a:hover,
.nav-bp > li.active > a:focus {
  color: #ffffff;
  background-color: #002245d4;
}
.btn_remove_meta{
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 20px;
  padding-top: 2px;
  color: #b3b3b3;
}
.modal-fixo{
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
.icon_black{
  color: #333 !important;
  cursor: pointer !important;
}
.icon_white{
  color: #fafafa !important;
  cursor: pointer !important;
}
.icon_solicitacao{
  content: url('../assets/img/icones_bp/processos.svg');
  cursor: pointer !important;
}

@media (max-width: 991px){
  .div_tabelas {
      padding-left: 0px !important;
      padding-right: 0px !important;
  }
}
@media (min-width: 992px){
  .div_tabelas {
      padding-right: 0px !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.card-box {
  position: relative;
  color: #fff;
  padding: 20px 10px 40px;
  margin: 20px 0px;
}
.card-box:hover {
  text-decoration: none;
  color: #f1f1f1;
}
.card-box:hover .icon i {
  font-size: 100px;
  transition: 1s;
  -webkit-transition: 1s;
}
.card-box:hover img {
  width: 80px !important;
  opacity: 1;
  transition: 1s;
  -webkit-transition: 1s;
}
.card-box .inner {
  padding: 5px 10px 0 10px;
}
.card-box h3 {
  font-size: 27px;
  font-weight: bold;
  margin: 0 0 8px 0;
  white-space: nowrap;
  padding: 0;
  text-align: left;
}
.card-box p {
  font-size: 15px;
}
.card-box .icon {
  position: absolute;
  top: auto;
  bottom: 5px;
  right: 5px;
  z-index: 0;
  font-size: 72px;
  color: rgba(0, 0, 0, 0.15);
}
.card-box img {
  top: auto;
  bottom: 5px;
  right: 5px;
  width: 65px !important;
  opacity: 0.4;
}
.card-box .card-box-footer {
  position: absolute;
  left: 0px;
  bottom: 0px;
  text-align: center;
  padding: 3px 0;
  color: rgba(255, 255, 255, 0.8);
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  text-decoration: none;
}
.card-box:hover .card-box-footer {
  background: rgba(0, 0, 0, 0.3);
}
.dx-scrollable-content{
  z-index: auto !important;
}
.btn{
  border-radius: 5px !important;
}