/*
 *
 * NEXTSI Bootstrap Select - 03/09/2015
 *
 * Autores: Diego Turco e Alisson Sanches
 *
 */

.ncbo_header ul,
.ncbo_item ul {
    margin: 0;
    padding: 0;
    width: 100%;
    display: inline-block;
    list-style-type: none;
}

.ncbo_scroll_desktop {
    overflow: scroll !important;
    overflow-x: hidden !important;
    max-height: 500px;
}

.ncbo_scroll_mobile {
    overflow: scroll !important;
    overflow-x: hidden !important;
    max-height: 150px;
}

.header_col {
    padding-left: 10px;
    line-height: 25px;
    float: left;
    overflow: hidden;
}

.detalhe_col {
    line-height: 25px;
    float: left;
    padding-left: 10px;
    overflow: hidden;
}
.multi-column-dropdown{
    cursor: default;
}

.select-header-hr{
    margin-top: 0px;
    margin-bottom: 10px;
}

.bg-cinza{
    background-color: #E6E6E6;
}

th .input-combobox-nxt {
    display: flex;
    display: -webkit-flex;
    width: 90%;
    width: calc(100% - 20px) !important;
}

/*Referente ao botão de desfazer - Aylon Muramatsu (01/06/2016)*/

.input-group.combobox .btn_desfazer:before {
    content: attr(data-on-icon);
    font-family: 'FontAwesome';
}

.input-group.combobox .btn_desfazer:after {
    content: attr(data-on-info);
}



[aria-labelledby=modal_grupo_usuarios_detalhes_label]  .input-combobox-nxt {
    display: flex;
    display: -webkit-flex;
    width: 90%;
    width: calc(100% - 20px) !important;
}

@supports not(width: calc(100% - 20px)){

    .input-combobox-nxt {
        width: 90%;
    }

}
