.tabela_projeto .executor{
	width: 100px;
}
.tabela_projeto [data-campo-especifico-bp="classificacao"]{
	width:120px;
}
.tabela_projeto .codigo{
	width:83px;
}
.tabela_padrao_bp:not(.tabela_projeto) .codigo{
	width: 63px;
}

.tabela_projeto .conclusao_previsto,
.tabela_projeto .inicio,
.tabela_projeto .inicio_previsto,
.tabela_projeto .conclusao
{
	width: 90px;
}

.tabela_projeto .status{
	width: 75px;
}

.tabela_padrao_bp .botoes_acoes{
	width: 113px;
}

/*
.tabela_padrao_bp tbody,
.tabela_padrao_bp thead,
.tabela_padrao_bp tfoot{
	font-size: 11px;
	font-family: "Roboto", sans-serif;
}
*/

.tabela_padrao_bp tbody > tr > td{
	vertical-align: middle;
}

.dados_projeto {
	font-size: 11px;
}

.tabela_padrao_bp .esconder_descricao_completa {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.tabela_padrao_bp .mais_detalhes{
	cursor: pointer;
	width: 10px;
}

.tabela_padrao_bp.table > thead > tr > th,
.tabela_padrao_bp.table > tbody > tr > th,
.tabela_padrao_bp.table > tfoot > tr > th,
.tabela_padrao_bp.table > thead > tr > td,
.tabela_padrao_bp.table > tbody > tr > td,
.tabela_padrao_bp.table > tfoot > tr > td {
	padding: 2px;
	line-height: 1.42857143;
	border-top: 1px solid #dddddd;
}

.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
	padding: 1px;
}

/*
.familia_fonte{
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
*/

/**
Aplicação de Padding VIA CSS (Melhor processo & Rápido)
**/
.tabela_projeto tbody [template-field=nome_atividade] span:nth-of-type(1){
	width: 1ch;
	margin-left:0ch;
	height:10px;
	float:left;
	display:table;
	box-sizing:border-box;
}
.tabela_projeto tbody [template-field=nome_atividade]  span.hidden + span{
	display:table;
	float:left;
	margin-left:0ch;
	box-sizing:border-box;

}

.tabela_padrao_bp > thead > tr th.status,
.tabela_padrao_bp > thead > tr th.dt_inicio ,
.tabela_padrao_bp > thead > tr th.dt_fim,
.tabela_padrao_bp > tbody > tr td.status,
.tabela_padrao_bp > tbody > tr td.dt_inicio ,
.tabela_padrao_bp > tbody > tr td.dt_fim{
	width:93px;
}

.tabela_padrao_bp > thead > tr th.percentual,
.tabela_padrao_bp > tbody > tr td.percentual,
.tabela_padrao_bp [data-bp-column="percentual"]{
	width:100px;
}

/**
EFECT Hover Detalhes
**/
.tabela_padrao_bp.table .mais_detalhes span.hover{
	color:#f14646 !important;
}
.tabela_padrao_bp{
	padding:0;
}
.tabela_padrao_bp .filtro_container input,
.tabela_padrao_bp select{
	border-radius:0;
	font-size:9px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	height:25px;
    padding:5px;
}
.tabela_padrao_bp .filtro_container .bootstrap-select.btn-group .btn .filter-option {
	height:20px;
	font-size:11px;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.tabela_padrao_bp .filtro_container .bootstrap-select > .btn{
	height:25px;
	margin:0;
}
.tabela_padrao_bp .filtro_container .form-control{
	height:25px;
}
.tabela_padrao_bp .filtro_container .bootstrap-select .text{
	font-size:11px;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

}

.tabela_padrao_bp .orderbypointer{
	position:relative;
}

.tabela_padrao_bp:not(.tabela_projeto) .icon_orderby.fa-sort-asc{
	position:absolute;
	top:5px;
}

.tabela_padrao_bp:not(.tabela_projeto) .icon_orderby.fa-sort-desc{
	position:absolute;
	top:0px;
}

.tabela_padrao_bp [data-bp-abrev="true"] *:nth-of-type(1){
	white-space: nowrap;
  	overflow: hidden;
  	text-overflow:ellipsis;
  	display: block;
  	width: inherit;
  	max-width: inherit;
}

.tabela_padrao_bp tbody tr.selected{
	background-color: rgba(46, 204, 113,0.12);
}

.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="assunto"] .texto{
	width: 100px;
	display: block;
}


@media (max-width:992px) {
	/****
	Responsividade Mobile
	***/
	/**Components Extras**/
	/**
	* CONFIGURAÇÕES  PADRÕES
	**/
	.tabela_padrao_bp:not([data-table-name="oportunidade"]) tbody [data-bp-column-header]:before{
		content:attr(data-bp-column-header);
		font-weight:bold;
		text-transform:capitalize;
	}

	/*** FIM CONFIGURAÇÕES PADRÕES ***/

	.tabela_padrao_bp tr.small-tr {
	    max-height: 30px !important;
	    min-height: 30px !important;
	    padding: 1px 5px !important;

	}
	.tabela_projeto.table-responsive{
		border:0px solid;
	}
	.tabela_padrao_bp thead tr:not(.filtro_container) th {
		display:none;
	}


	/**CONFIGURAÇÃO BOTÃO - Mobile**/
	.tabela_padrao_bp .botoes_acoes{
		width:auto;

	}
	.tabela_padrao_bp .botoes_acoes{
			width:auto;

		}

	.tabela_padrao_bp:not(.tabela_projeto) thead {
		display:block;
		width:98%;
		max-width:85vw;

	}
	.tabela_padrao_bp:not(.tabela_projeto) thead > tr.filtro_container{
		width:100%;
		height:auto;
		display:block;

	}
	.tabela_padrao_bp:not(.tabela_projeto) thead > tr.filtro_container th.botoes_acoes,
	.tabela_padrao_bp:not(.tabela_projeto) thead > tr.filtro_container th.hidden-padrao{
		display:none;
	}
	.tabela_padrao_bp:not(.tabela_projeto) thead > tr.filtro_container th:not(.botoes_acoes):not(.status){
		width:90px !important;
		float:left;
		margin:5px;
	}
	.tabela_padrao_bp:not(.tabela_projeto) thead > tr.filtro_container th.status{

		display:none;


	}

	.tabela_padrao_bp:not(.tabela_projeto){
		border:0;

	}
	.tabela_padrao_bp:not(.tabela_projeto) tbody > tr{

		display:block;
		width:100%;
		max-width:95vw;
		padding:25px 10px;
		box-sizing:border-box;
		min-height:140px;
		position:relative;
		border-top:1px solid #c2c2c2;
		border-left:1px solid #c2c2c2;
		border-right:1px solid #c2c2c2;

	}
	.tabela_padrao_bp:not([data-table-name="oportunidade"]):not(.tabela_projeto) tbody > tr td{
		float:left;
		display:block;
		border:0 !important;

		position:relative;

	}

	.tabela_padrao_bp:not([data-table-name="oportunidade"]):not(.tabela_projeto) [data-bp-column="status"]{

		position:absolute;
		top:5px;
		right:5px;
	}

	.tabela_padrao_bp:not([data-table-name="oportunidade"]):not(.tabela_projeto) tbody [data-bp-column="codigo"]{
		display:block;
		width:80px;
		max-width:80px;
		white-space:nowrap;
		overflow-x:hidden;
		text-overflow:ellipsis;
		position:absolute;
		top:5px;
	}
	.tabela_padrao_bp:not([data-table-name="oportunidade"]):not(.tabela_projeto) tbody [data-bp-column="nome"]{
		width:100%;
		height:35px;
		overflow:hidden;
		text-overflow:ellipsis;
		font-weight:bold;
	}

	.tabela_padrao_bp:not([data-table-name="oportunidade"]):not(.tabela_projeto) tbody .botoes_acoes{
		position:absolute;
		right:5px;
		bottom:5px;
	}

	.tabela_padrao_bp:not([data-table-name="oportunidade"]):not(.tabela_projeto) tbody [data-bp-column="dt_inicio"]{
		width:70px;
	}

	.tabela_padrao_bp:not([data-table-name="oportunidade"]):not(.tabela_projeto) tbody [data-bp-column="dt_inicio"]:before{
		content:attr(data-bp-column-header);
		display:table;
		font-weight:bold;
		text-transform:capitalize;
	}

	.tabela_padrao_bp:not([data-table-name="oportunidade"]):not(.tabela_projeto) tbody [data-bp-column="dt_fim"]{
		width:70px;
	}

	.tabela_padrao_bp:not([data-table-name="oportunidade"]):not(.tabela_projeto) tbody [data-bp-column="dt_fim"]:before{
		content:attr(data-bp-column-header);
		display:table;
		font-weight:bold;
		text-transform:capitalize;
	}

	.tabela_padrao_bp:not([data-table-name="oportunidade"]):not(.tabela_projeto) tbody [data-bp-column="percentual"]{
		width:70px;
	}

	.tabela_padrao_bp:not([data-table-name="oportunidade"]):not(.tabela_projeto) tbody [data-bp-column="percentual"]:before{
		content:attr(data-bp-column-header);
		display:table;
		font-weight:bold;
		text-transform:capitalize;
	}

	.tabela_padrao_bp:not([data-table-name="oportunidade"]) tbody [data-bp-column-header]:before{
		content:attr(data-bp-column-header);
		display: table;
		font-weight:bold;
		text-transform:capitalize;
	}

	.tabela_padrao_bp:not([data-table-name="oportunidade"]) tbody [data-bp-column="status"] ~ [data-bp-column="status_atividades"]{
	    position: absolute;
	    top: 20px;
	    right: 5px;

	}
	/**
	* CONFIGURAÇÕES Listagem Oportunidade
	**/
	.tabela_padrao_bp[data-table-name="oportunidade"]{
		position: relative;
	}
	.tabela_padrao_bp[data-table-name="oportunidade"] tbody tr td {
		display: table;
		border: 0;
		margin: 5px;
		position: relative;
	}
	.tabela_padrao_bp[data-table-name="oportunidade"] [data-bp-column="oportunidade"]{
		font-size: 1.15em;
		font-weight: bold;
	}
	.tabela_padrao_bp[data-table-name="oportunidade"] [data-bp-column="btn-acoes"]{
	    right: 0px;
	    float: right;
	    clear: both;
	    margin-top: -15px;
	    display: block !important;
	}
	.tabela_padrao_bp[data-table-name="oportunidade"] [data-bp-column="status"]{
		position: absolute;
		top: 0px;
	}
	.tabela_padrao_bp[data-table-name="oportunidade"] [data-bp-column="situacao"]{
		position: absolute;
		top: 0px;
		right: 0px;
	}

	.tabela_padrao_bp .elem-hide{
		display: none !important;
	}

	/*
	* CONFIGURAÇÕES Atividade Projeto
	*/


	.tabela_padrao_bp[data-table-name="atividade_projeto"]  td {
		display: block ;
		border: 0 !important;
		position: absolute;
 		visibility: hidden;

	}




	.tabela_padrao_bp[data-table-name="atividade_projeto"] tbody tr[template-field="descricao"]{
		padding:5px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		position:relative !important;
		min-height: 40px;
		width:100%;
		border-top:1px solid #c2c2c2 !important;
		border-left:1px solid #c2c2c2 !important;
		border-right:1px solid #c2c2c2 !important;
		border-bottom:1px solid #c2c2c2 !important;

	    display: -webkit-box;
		-webkit-flex-direction: column;
		display: -moz-box;
		-moz-flex-direction: column;
		display: -ms-flexbox;
		-ms-flex-direction: column;
		display: -webkit-flex;
		display: flex;
		flex-direction: row;
	}

	.tabela_padrao_bp[data-table-name="atividade_projeto"] tbody tr[template-field="descricao"] td{
		width:100%;
		border:0;
		border-left:1px solid #c2c2c2;
		border-right:1px solid #c2c2c2;
	    display: table;
	    position: relative;
	    visibility:visible;
	}

	.tabela_padrao_bp[data-table-name="atividade_projeto"] tfoot td{
		width:100%;
		border:0;
		border-left:1px solid #c2c2c2 !important;
		border-right:1px solid #c2c2c2 !important;
		border-top:1px solid #c2c2c2 !important;
		border-bottom:1px solid #c2c2c2 !important;

	    display: block;
	    position: relative;
	    visibility:visible;
	}

	.tabela_padrao_bp[data-table-name="atividade_projeto"] tbody tr:not([template-field="descricao"])
	{
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		/*display:block;*/
		position:relative !important;
		min-height: 80px;
		width:100%;
		border-top:1px solid #c2c2c2;
		border-left:1px solid #c2c2c2;
		border-right:1px solid #c2c2c2;
		display: block;
		padding-left:15px;
		padding-right:15px;
		padding-top:15px;
		padding-bottom:0px;
		font-weight:lighter !important;
	}

	.tabela_padrao_bp[data-table-name="atividade_projeto"] tbody tr:not([template-field="descricao"]):before{
        content:"(" attr(data-indicador-tipo) ") Árvore - Nível " attr(data-nivel);
        font-weight:bold;
        position: absolute;
        top: 5px;
        left: 5px;
        color: #777;
        font-size: 85%;
    }

    .tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="codigo"]{
		visibility:visible;
		display:table;
		margin-top:5px;
		margin-left:-9px;
		font-weight:lighter;
		height:15px;
		position:relative;

	}

	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="nome_atividade"]{
		visibility:visible;
		display:table;
		position:relative;
		margin-left:-9px;


	}
	.tabela_padrao_bp[data-table-name="atividade_projeto"] tr[data-tipo="S"] [data-bp-column="nome_atividade"] span:nth-of-type(1){
		margin-right:5px;
		margin-left: 0px !important;
	}
	.tabela_padrao_bp[data-table-name="atividade_projeto"] tr[data-tipo="A"] [data-bp-column="nome_atividade"] span:nth-of-type(2){
		margin-right:5px;
		margin-left: 15px !important;
	}
	.tabela_padrao_bp[data-table-name="atividade_projeto"] tr[data-tipo="A"] [data-bp-column="mais_detalhes"] {
		visibility:visible;
		top:34px;
		left:4px;
		width:15px;
		z-index:3;
	}
	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="nome_atividade"] .nome{
		margin-left:0 !important;
	}

	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="assunto"]{
		visibility:visible;
		margin-left:-9px;
		width:97%;
		width:calc(100% - 12px);
		display:block;
		margin-left:-9px;
		height:30px;
		position:relative;
	}
	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="assunto"]:before{
		content:'Assunto:';
		display:block;
		float:left;
		margin-right:5px;
	}

	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="assunto"] .texto{
		display:block;
		display:-webkit-box;
		width:100%;
		height:30px;
		text-indent:50px;
		position:relative;
		top:-14px;
		-webkit-line-clamp:2;
		-webkit-box-orient:vertical;
		white-space:normal !important;
		padding-bottom:6px;

	}

	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"]{
		display:flex;
		display:-webkit-box;
		box-sizing:border-box;
		-webkit-box-sizing:border-box;
		width:100%;
		min-width:251px;
		height:auto;
		visibility:visible;
		position:relative;
		margin-left:-9px;
		margin-right:-12px;
		flex-direction:row;
		font-size:0;

	}

	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile {

		height:auto;
		min-height:20px;
		width:100%;
		min-width:100%;
		position:relative;
		visibility:visible;
		margin-left:-2px;
		margin-top:-2px;
		display:flex;
		display:-webkit-box;
		width:auto;
		max-width:100%;
		min-width:100%;


	}
	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile div{
		margin-left:2px !important;
		margin-right:2px !important;

	}
	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile div:nth-of-type(3n + 1 ){
		margin-left:0px !important;

	}

	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile .inicio{
		visibility:visible;
		width:75px;
		height:25px;
		display:table;
		float:left;
	}


	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile .inicio_previsto{
		visibility:visible;
		width:75px;
		height:25px;
		display:table;
		display:-webkit-box;
		float:left;

	}


	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile .percentual_conclusao{
		visibility:visible;
		width:75px;
		height:25px;
		display:table ;
		float:left;
		clear:right;
		margin-bottom:10px;
	}

	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile .conclusao_previsto{
		visibility:visible;
		display:table;
		position:relative;
		width:75px;
		height:25px;
		clear:left;
		float:left;
	}

	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile .conclusao{
		visibility:visible;
		display:table;
		position:relative;
		width:75px;
		height:25px;
		float:left;
	}

	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile .saldo_horas{
		visibility:visible;
		display:table;
		position:relative;
		float:left;
		width:75px;
		height:25px;
		clear:right;
		margin-bottom:10px;
	}

	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile .horas_previstas{
		visibility:visible;
		display:table;
		position:relative;
		width:100px;
		height:25px;
		float:left;
	}

	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile .horas_trabalhadas{
		visibility:visible;
		display:table;
		position:relative;
		width:100px;
		height:25px;
	}

	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile .classificacao{
		visibility:visible;
		display:block;
		position:relative;
		width:100%;
		height:25px;
		margin-top:15px !important;
		margin-left:-0px !important;
		clear:both;
	}

	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="status"] {
		display:table;
		visibility:visible;
		bottom:5px;
		left:5px;
	}

	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="botoes"] {
		display:block;
		position:relative;
		visibility:visible;
		width:200px;
		margin-top:4px;
	}
	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="botoes"] {
		display:inline-block !important;
		position:relative;
		visibility:visible;
		width:auto;
		float:right;
		clear:both;
		bottom:2px;
		right:-9px;
	}

	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="auxiliar"]  {
		clear:both;
		position:relative;
		display:block !important;
		height:2px;
		width:100%;
		visibility:visible;
	}
	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile [column-active="false"]{
		display: none !important;
	}

	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile .inicio_previsto:before,
	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile .inicio:before,
	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile .percentual_conclusao:before,
	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile .conclusao_previsto:before,
	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile .conclusao:before,
	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile .saldo_horas:before,
	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile .horas_previstas:before,
	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile .horas_trabalhadas:before{
		content:'\00a0' attr(data-bp-column-header)'\00a0' '\a' attr(data-bp-column-value);
		visibility:visible;
		font-size:10px;
		white-space:pre;
		text-align:left;
		font-weight:normal !important;
		font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
	}

	.tabela_padrao_bp[data-table-name="atividade_projeto"] [data-bp-column="inicio_previsto"] .fix-mobile .classificacao:before{
		content: attr(data-bp-column-header) ':' attr(data-bp-column-value);
		visibility:visible;
		font-size:10px;
		white-space:pre-wrap !important;
		text-align:left;
		font-weight:normal !important;
		font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
		width:100%;
		display:block;
		display:-webkit-box;
		height:30px;
		-webkit-line-clamp:2;
		-webkit-box-orient:vertical;
		text-overflow:ellipsis;
		overflow:hidden;

	}
	.tabela_padrao_bp[data-table-name="historico_agenda"] [data-bp-column="dia"]{
		display:flex;
		display:-webkit-box;
		box-sizing:border-box;
		-webkit-box-sizing:border-box;
		width:100%;
		min-width:251px;
		height:auto;
		visibility:visible;
		position:relative;
		margin-left:-9px;
		margin-right:-12px;
		flex-direction:row;
		font-size:0;

	}

	.tabela_padrao_bp[data-table-name="historico_agenda"] [data-bp-column="dia"] .fix-mobile {

		height:auto;
		min-height:20px;
		width:100%;
		min-width:100%;
		position:relative;
		visibility:visible;
		margin-left:-2px;
		margin-top:-2px;
		display:flex;
		display:-webkit-box;
		width:auto;
		max-width:100%;
		min-width:100%;
		margin-left:10px;

	}

	.tabela_padrao_bp[data-table-name="historico_agenda"] [data-bp-column="dia"] .fix-mobile .dia:before,
	.tabela_padrao_bp[data-table-name="historico_agenda"] [data-bp-column="dia"] .fix-mobile .acao_data:before,
	.tabela_padrao_bp[data-table-name="historico_agenda"] [data-bp-column="dia"] .fix-mobile .acao:before
	{
		content:'\00a0' attr(data-bp-column-header)'\00a0' '\a' attr(data-bp-column-value);
		visibility:visible;
		font-size:10px;
		white-space:pre;
		text-align:left;
		font-weight:normal !important;
		font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
	}


	.tabela_padrao_bp[data-table-name="historico_agenda"] [data-bp-column]:not([data-bp-column="dia"]){
		font-weight:normal !important;
		font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
		font-size:10px;
	}
	.tabela_padrao_bp[data-table-name="historico_agenda"] [data-bp-column="dia"] .fix-mobile .dia:before{
		margin-right:25px;
	}

	.tabela_padrao_bp[data-table-name="historico_agenda"] [data-bp-column="dia"] .fix-mobile .acao:before{
		margin-left:25px;
	}
	.tabela_padrao_bp[data-table-name="historico_agenda"] [data-bp-column="de"]{
		display:table !important;
		min-width:140px;
		width:auto;
		margin-left:0px;
		position:relative;
		font-weight:normal !important;
		font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
		font-size:10px;
		margin-right:1ch;
	}

	.tabela_padrao_bp[data-table-name="historico_agenda"] [data-bp-column="acao_usuario"]{
		display:table !important;
		min-width:230px;
		width:auto;
		position:relative;
	}

	.tabela_padrao_bp[data-table-name="historico_agenda"] [data-bp-column="detalhes"]{
		min-width:200px;
		padding-right:5px;
		width:auto;
		margin-right:10px;
		margin-right:1ch;
	}

	.tabela_padrao_bp[data-table-name="historico_agenda"] [data-bp-column="acao_data"],
	.tabela_padrao_bp[data-table-name="historico_agenda"] [data-bp-column="acao"]
	{
		display:none !important;
		visibility:none !important;
	}

	.tabela_padrao_bp[data-table-name="historico_agenda"]{
		position: relative;
	}
	.tabela_padrao_bp[data-table-name="historico_agenda"] tbody tr td {
		display: table !important;
		border: 0;
		position: relative;
	}

	.tabela_padrao_bp[data-table-name="confirmar_recorrencia"] tbody tr{
		min-height:50px !important;
		padding-top:10px;

	}
	.tabela_padrao_bp[data-table-name="confirmar_recorrencia"] tbody tr td{
		display:table !important;
	}

	.tabela_padrao_bp[data-table-name="confirmar_recorrencia"] [data-bp-column="hora_inicio"],
	.tabela_padrao_bp[data-table-name="confirmar_recorrencia"] [data-bp-column="hora_fim"],
	.tabela_padrao_bp[data-table-name="confirmar_recorrencia"] [data-bp-column="gerar"]
	{
		display:none !important;
		visibility: none !important;
	}
	/**
	 * [.fix-mobile Elemento responsável por exibir instruções css especificas no mobile]
	 * É necessário adicionar o seguinte padrão para a coluna que possui a classe fix-mobile,
	 * font-size: 0 -> Para que o texto da tabela desktop não seja exibida
	 */
	.tabela_padrao_bp[data-table-name="confirmar_recorrencia"] [data-bp-column="dia"]{
		font-size:0;
		width:100% !important;
		margin-left:-10px;
	}

	/**
	 * fix-mobile Elemento responsável por exibir instruções css especificas para o mobile
	 * É a configuração do container do fix mobile
	 */
	.tabela_padrao_bp[data-table-name="confirmar_recorrencia"] [data-bp-column="dia"] .fix-mobile{
		height:auto;
		width:100%;
		min-height:30px;
		min-width:100%;
		position:relative;
		visibility:visible;
		margin-left:-2px;
		margin-top:-2px;
		display:block !important;
		width:auto;
		max-width:100%;
		min-width:100%;
		margin-left:10px;
	}

	/**
	 * Colunas especificas
	 */

	.tabela_padrao_bp[data-table-name="confirmar_recorrencia"] [data-bp-column="dia"] .fix-mobile .dia:before
	{
		content:'\00a0' attr(data-bp-column-header)'\00a0' '\a' attr(data-bp-column-value);
		visibility:visible;
		font-size:10px;
		white-space:pre;
		text-align:left;
		font-weight:normal !important;
		font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
		margin-right:15px !important;
		display:table;
		float:left;
	}

	.tabela_padrao_bp[data-table-name="confirmar_recorrencia"] [data-bp-column="dia"] .fix-mobile .hora_inicio:before{
		content:'\00a0' attr(data-bp-column-header)'\00a0' '\a' attr(data-bp-column-value);
		visibility:visible;
		font-size:10px;
		white-space:pre;
		text-align:left;
		font-weight:normal !important;
		font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
		margin-right:5px !important;
		display:table;
		float:left;
	}

	.tabela_padrao_bp[data-table-name="confirmar_recorrencia"] [data-bp-column="dia"] .fix-mobile .hora_fim:before{
		content:'\00a0' attr(data-bp-column-header)'\00a0' '\a' attr(data-bp-column-value);
		visibility:visible;
		font-size:10px;
		white-space:pre;
		text-align:left;
		font-weight:normal !important;
		font-family:"Helvetica Neue", Helvetica, Arial, sans-serif;
		margin-right:5px !important;
		display:table;
		float:left;
	}

	.tabela_padrao_bp[data-table-name="confirmar_recorrencia"] [data-bp-column="dia"] .fix-mobile .gerar{
		float:right;
		margin-top:5px;
		display:table !important;
	}

	.tabela_padrao_bp[data-table-name="confirmar_recorrencia"] [data-destacar]:before{
		background-color: #f2dede !important;
	}

	.tabela_padrao_bp[data-table-name="tarefas"] tbody tr{
		display:flex;
		display:-webkit-flexbox;
		flex:1;
		flex-direction: row;
		height:30px !important;
		min-height:30px !important;
		justify-content: 'flex-end';
		align-items: center;
	}
	.tabela_padrao_bp[data-table-name="tarefas"] [data-bp-column="realizado"]{
			display:flex;
			display:-webkit-flexbox;
			max-width:39px;
			max-height:25px;
			align-items: center;
			top:-15px;
			left:-8px;
	}
	.tabela_padrao_bp[data-table-name="tarefas"] [data-bp-column="descricao"]{

			display:flex;
			display:-webkit-flexbox;
			flex-direction: row-reverse;
			justify-content: center;
			flex:3;
			max-height:30px;
			min-width:100px;
			text-overflow: ellipsis;
			overflow-x: hidden;
			overflow-y:hidden;
			left:-5px;
	}

	.tabela_padrao_bp[data-table-name="tarefas"] [data-bp-column="acao"]{
		display:flex;
		display:-webkit-flexbox;
		flex-direction: row-reverse;
		justify-content: center;
		max-width:30px;
	}

	.tabela_padrao_bp[data-table-name="tarefas"] [data-bp-column="acao"] .btn{
			display:table;
			width:30px;
			clear:both;
			left:0px;
			position:relative;
	}

	.tabela_padrao_bp[data-table-name="tarefas"] [data-bp-column="acao"] .btn:nth-of-type(1){
			display:table;
			width:30px;
			clear:both;
			left:-1px;
			position:relative;
	}
}
